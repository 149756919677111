import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, Button, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  info: { paddingLeft: "30px", paddingRight: "30px" },
  form: {
    backgroundColor: "#424242",
    padding: "30px",
  },
  download: {
    textDecoration: "None",
  },
  white: {
    color: "white",
  },
}));

const MembershipFormDownload: React.FunctionComponent = () => {
  const classes = useStyles();

  const Info = () => {
    return (
      <div className={classes.info}>
        <Typography>
          To become a member or renew your membership please make payment via
          BACS then complete the membership form below.
          <br />
          <br />
          If you don't have the club's bank details please email.
          <br />
          <br />
          New members should make contact and arrange to visit the club before
          filling out the form and making payment.
          <br />
          <br />
          <u>
            When making payment please use your surname and initial as your
            reference.
          </u>
        </Typography>

        <br />
        <Typography color="primary">
          For queries email us at{" "}
          <Link href="mailto:enquiries@phoenixcpc.co.uk">
            <Typography color="secondary">
              enquiries@phoenixcpc.co.uk
            </Typography>
          </Link>
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Typography variant="h5">Membership Form</Typography>
        <br />
        <div>
          <Grid container>
            <Info />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default MembershipFormDownload;
