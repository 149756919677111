import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { withStyles, createStyles, WithStyles } from "@material-ui/core";
import Drawer from "./Drawer";

interface LayoutProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  outsideComponent?: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const classes = props.classes;

  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <Header openSidebar={() => setOpen(true)} />
      <Drawer open={open} setOpen={setOpen} />
      <div className={classes.container}>{props.children}</div>
      <div>{props.outsideComponent}</div>
      <Footer />
    </React.Fragment>
  );
};

const styles = createStyles({
  container: {
    padding: "25px",
  },
});

export default withStyles(styles)(Layout);
