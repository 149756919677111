import React from "react";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import MembershipFormDownload from "../components/MembershipFormDownload";
import { Grid, Link, Typography } from "@material-ui/core";

interface MembershipProps extends WithStyles<typeof styles> {}

const MembershipForm = () => {
  return (
    <Grid>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSf1jSL25e3yW2ffnwoiwarFCc6p8u5P8R7x5Y9PDLbxxmqhMQ/viewform?embedded=true"
        width="100%"
        height="1224"
        title="Membership Form"
        style={{ border: "none" }}
      >
        Loading…
      </iframe>
      <br />
      <div style={{ margin: "auto" }}>
        <Link href="https://docs.google.com/forms/d/e/1FAIpQLSf1jSL25e3yW2ffnwoiwarFCc6p8u5P8R7x5Y9PDLbxxmqhMQ/viewform">
          <Typography color="secondary">Direct link to the form</Typography>
        </Link>
      </div>
    </Grid>
  );
};

const Membership = (props: MembershipProps) => {
  return (
    <React.Fragment>
      <Layout outsideComponent={<MembershipForm />}>
        <PageTitle text="Membership" />

        <MembershipFormDownload />
      </Layout>
    </React.Fragment>
  );
};

const styles = createStyles({});

export default withStyles(styles)(Membership);
