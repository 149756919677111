import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", , , , , "7th Jan", "21st Jan", "4th Feb", "18th Feb", "3rd Mar", "7th Apr", "21st Apr", "5th May", "19th May", "2nd June", "16th June", "30th June", "7th July", "21st July", "4th Aug", "18th Aug", "1st Sept", "15th Sept", "29th Oct", "6th Oct", "20th Oct", "3rd Nov", "17th Nov", "1st Dec", "15th Dec", "29th Dec"];

export const results: (string | number | undefined)[][] = [
["ALLEN JOHN", "B", 51.4, 13, 669, , 51, 56, , 50, 43, 56, , 51, 53, , 51, , 49, , 57, 50, , , 51, , , , , 50, ]
,["AZZOPARDI MATT", "A", 62.9, 1, 63, , 63, , , , , , , , , , , , , , , , , , , , , , , , ]
,["BARROW DAVID", "B", 46.9, 7, 328, , , 49, , 50, 40, 57, , , , 46, , , , , , , , 49, , , , , 38, , ]
,["BEALING DAVID", "A", 57.3, 7, 401, , , , , , , , , , , , , , , , , 56, 77, 70, , , 61, 60, 37, 40, 50]
,["BLUNDELL MARK", "C", 22.9, 1, 23, , , , , , , , , , , 23, , , , , , , , , , , , , , , ]
,["BROWN DAVID", "AA", 76.6, 21, 1608, 60, 59, 73, 89, 79, 73, 79, 81, 80, , , 79, 74, 79, 83, 90, 81, 86, 76, , , 80, 70, 67, 71, 76]
,["CAMERON DEAN", "A", 63.6, 17, 1081, 69, 53, , 84, 56, , , , , 61, 60, 59, 67, 66, , 71, 66, , 57, 63, 59, , 63, 61, 67, 61]
,["CARR-GOMM MAH", "C", 42.6, 3, 128, , 43, 43, , , , , , , , , , , , , , , , , , , , , , 42, ]
,["CHECHOTKIN VLADYSLAV ", "AAA", 78.3, 11, 861, 74, 67, , 83, 84, , 84, , , 80, 74, , 86, , , , , , 84, 77, , , , 67, , 86]
,["COLE BERNARD", "B", 55.8, 18, 1004, 51, 57, 66, , , 56, 60, 50, 67, , 53, 54, , 59, 39, 51, 53, , 60, 57, , 60, 61, , 50, ]
,["CRAWFORD MARTIN", "C", 42.9, 1, 43, , 43, , , , , , , , , , , , , , , , , , , , , , , , ]
,["CULLINGHAM SAM", "A", 60.4, 4, 242, , , , 56, 63, , , , , 69, , , , , , , , , , 54, , , , , , ]
,["CULLINGHAM TATE", "C", 20.2, 3, 61, , , , 27, , , , , , 6, , , , , , , , , , 28, , , , , , ]
,["DAWSON PETER", "A", 58.9, 16, 942, 56, 43, 69, 79, 50, , , 67, 77, , , 46, , 63, 49, 67, , 70, 51, 44, , 51, 60, , , ]
,["DEACON ROBERT", "B", 57.3, 13, 745, , , , , , , , , 63, 54, 43, , , 47, 53, 63, 71, 59, 56, 61, 71, , , 44, 59, 56]
,["DE SILVA RICKY", "B", 49.1, 20, 982, 48, 34, 31, 56, , 49, 64, 47, 56, 60, 54, , , 41, 46, 53, , 51, 50, 46, 51, 47, 53, , 44, 61]
,["DHIR VARINDER", "A", 59.7, 21, 1254, 64, 59, 63, 76, 51, , 77, 60, 70, 61, 57, 67, 66, 57, 47, 64, 51, 63, , 69, , 43, , 39, 50, 66]
,["DUFFELL ERIC", "A", 57.6, 14, 807, 46, , 50, , 56, 57, 66, , 80, 51, , , , , , 66, 67, , , 57, 63, 44, 51, 52, , ]
,["DUKE NEIL", "A", 55.8, 19, 1061, , 53, 51, 80, 57, 27, 61, 64, 64, 69, , 54, , 69, , , 60, , 61, 54, 51, 59, 30, 40, 56, ]
,["DYER PAUL", "B", 46.1, 23, 1059, 53, 61, 54, 63, 47, 36, 56, 47, 51, 50, 50, 50, , 43, 47, 51, , 57, 33, 39, 29, 36, 46, 23, 38, ]
,["ESCOTT PAUL", "A", 64.0, 3, 192, , , , 69, , , , , 60, , , , , , , 63, , , , , , , , , , ]
,["ESCOTT RICHARD", "A", 65.4, 23, 1504, 77, 70, 67, 74, 63, 51, 79, 70, 74, 56, 66, 63, 74, 61, 56, 63, 66, 69, 56, , 54, 74, , 56, 66, 67]
,["ESCOTT TIM", "AA", 74.9, 20, 1499, 70, 74, 84, 89, 77, 66, 86, 79, 77, 86, 81, 81, 70, , , 63, , , 73, 66, 64, 79, , 69, 66, 80]
,["FARAZAMAND A", "C", 57.8, 3, 173, 40, , , , , , , , , , , , , , , , , , , , , , 70, , 63, ]
,["FARAZAMAND J", "B", 56.5, 4, 226, 50, , , , , , , 63, , , , , , , , , , , , , , , 50, , 63, ]
,["HEALEY LES", "AAA", 82.8, 9, 745, , , , , , , 81, , 89, , 67, 84, , 83, 83, , , , 83, , , , 81, , 93, 84]
,["HIBBERT PAUL", "AAA", 83.9, 17, 1426, 74, 87, 74, 86, 84, , , , 90, , 80, 80, 83, , 90, , 89, 93, 89, 96, , 77, 81, , 73, 90]
,["HOLLOWAY NICK", "B", 49.7, 4, 199, 23, , , , , , , , , , , , , , , , 54, 68, , , , , 54, , , ]
,["HOOLEY PETER", "B", 49.8, 4, 199, , , , , , , , , , 38, , , 62, , , , , , 46, , , , , , 53, ]
,["HOWORTH CHRIS", "C", 44.2, 15, 664, , , 41, , 47, 41, 57, , , , , 39, 43, 43, 37, 50, 46, 50, , , , 46, 37, 40, 47, 60]
,["JACKSON COLIN", "B", 48.4, 12, 581, 46, 28, 44, 60, , 43, 54, , 54, 54, , , 60, , , , 42, 54, , , , , 42, , , ]
,["JAY CHRIS", "AA", 70.8, 13, 920, , 66, , 71, 76, 56, , 73, 84, , 53, 59, , , 74, 80, , 77, 79, , , 73, , , , ]
,["MICHAEL JOHNSON", "B", 58.6, 1, 59, 59, , , , , , , , , , , , , , , , , , , , , , , , , ]
,["KING JONATHAN", "A", 59.9, 3, 180, 50, , , 84, , , , , , , , , , , , , , , , , , , , 46, , ]
,["MANUEL RAYNOR", "AA", 73.4, 22, 1614, 71, 67, , 90, 83, 67, 86, 79, 71, 63, 76, , 71, 81, , 79, 69, 76, 73, 56, 63, 70, 67, 77, 80, 73]
,["MARCHETTI ALAN", "C", 43.6, 3, 131, , , , , , , , , , , , , , , 41, , , 41, , , , , , 48, , ]
,["MORGAN-RUSSELL TIM", "B", 53.1, 4, 213, , , , , , , , , , 43, 44, , , , , , , 74, , , , , , , 51, 74]
,["O'GORMAN ROY", "B", 51.9, 9, 467, 49, , , , , 43, 56, , , , , , , , , 64, 51, , , 59, , 50, 51, 44, , ]
,["PAYNE BARRY", "B", 50.5, 6, 303, , , 50, , , , , 57, 64, 50, , 44, , 37, , , , , , , , , , , , ]
,["PRENTICE ROBERT", "A", 64.0, 8, 512, , , , , , , 71, , 71, 59, , , , , , , 70, , 61, , , 59, , 53, 68, 66]
,["SEWELL WAYNE", "A", 69.3, 12, 831, 59, , , , , 63, 73, , 83, 51, , , , 77, 69, , 66, 73, , 60, , , 79, , 80, 81]
,["SHARP RUSSELL", "A", 56.8, 14, 796, , , 57, 73, , , , 56, , , , 49, 56, 54, 60, 63, , 63, , , 51, 49, 66, 44, 56, 61]
,["SMALLRIDGE ROSS", "B", 59.3, 2, 119, , , , , , , , , , 50, , , , , , 69, , , , , , , , , , ]
,["SMALLDRIDGE STEVE", "A", 69.2, 13, 900, , , , , , , 73, , 73, 83, 63, , , 69, 66, , 61, 63, 71, , 64, 81, , 56, 77, 61]
,["SOUTHALL IAN", "C", 27.1, 1, 27, , , , , , , , , , , 27, , , , , , , , , , , , , , , ]
,["STANLEY WARWICK ", "C", 39.0, 12, 467, , 29, , 46, , , 37, 39, 53, , 37, , , , 31, 50, 51, , , 44, , , , 20, 30, ]
,["SULLIVAN MARTIN", "AA", 69.4, 15, 1041, , 73, 76, 73, , , , , , 76, 67, 66, 67, , , 69, 69, 67, 81, , , 60, 66, 60, 72, 64]
,["THOMAS DUNCAN", "A", 60.0, 20, 1200, 50, , 61, 71, , 56, 63, 56, 50, 53, 46, 60, 66, 67, 54, 59, 63, 70, 73, , , 54, 66, , 63, 56]
,["TRUSTHAM MATT", "B", 56.7, 11, 624, 53, , 64, , 57, , , , , , , 54, 47, 66, , , , 51, , 46, , 63, , 60, 62, 61]
,["TURNER MICHAEL", "C", 47.1, 1, 47, , , , , , , 47, , , , , , , , , , , , , , , , , , , ]
,["VAUGHAN PAYNE R", "C", 32.1, 2, 64, , , 40, , , 24, , , , , , , , , , , , , , , , , , , , ]
,["VOWLER ALAN", "C", 41.8, 17, 711, , 41, 33, 66, , , 43, 54, , , 37, 50, , 39, 40, , , 39, 37, 44, 30, 39, 34, 44, 41, ]
,["WESTON DAVE", "A", 68.3, 9, 614, , , , , , , 57, , , , , , , , 74, 71, 67, , 63, 70, , 70, 61, , 80, ]
,["WESTON JADEN", "AA", 71.8, 4, 287, , , , , , , , , , , , , , , , 79, 69, 81, 59, , , , , , , ]
,["WHITE ROGER", "B", 47.5, 21, 998, 48, 32, , 74, , 49, 58, 56, 37, 43, , 62, 34, 38, 49, 54, 56, 54, 46, 37, 38, , 46, 40, 48, 54]
,];

export const shootStats: ShootStat[] = [
{"numberOfEntrants": 24, "average": 55.8, "high": 77.0, "low": 23.0},
{"numberOfEntrants": 23, "average": 54.5, "high": 87.0, "low": 28.0},
{"numberOfEntrants": 23, "average": 56.3, "high": 84.0, "low": 31.0},
{"numberOfEntrants": 24, "average": 71.6, "high": 90.0, "low": 27.0},
{"numberOfEntrants": 18, "average": 62.8, "high": 84.0, "low": 47.0},
{"numberOfEntrants": 19, "average": 49.5, "high": 73.0, "low": 24.0},
{"numberOfEntrants": 26, "average": 64.7, "high": 86.0, "low": 37.0},
{"numberOfEntrants": 18, "average": 61.0, "high": 81.0, "low": 39.0},
{"numberOfEntrants": 25, "average": 67.6, "high": 90.0, "low": 37.0},
{"numberOfEntrants": 25, "average": 56.8, "high": 86.0, "low": 6.0},
{"numberOfEntrants": 22, "average": 54.7, "high": 81.0, "low": 23.0},
{"numberOfEntrants": 21, "average": 59.6, "high": 84.0, "low": 39.0},
{"numberOfEntrants": 16, "average": 64.1, "high": 86.0, "low": 34.0},
{"numberOfEntrants": 22, "average": 58.5, "high": 83.0, "low": 37.0},
{"numberOfEntrants": 21, "average": 56.6, "high": 90.0, "low": 31.0},
{"numberOfEntrants": 25, "average": 64.4, "high": 90.0, "low": 50.0},
{"numberOfEntrants": 25, "average": 61.8, "high": 89.0, "low": 42.0},
{"numberOfEntrants": 25, "average": 65.0, "high": 93.0, "low": 39.0},
{"numberOfEntrants": 26, "average": 63.0, "high": 89.0, "low": 33.0},
{"numberOfEntrants": 23, "average": 55.6, "high": 96.0, "low": 28.0},
{"numberOfEntrants": 13, "average": 52.9, "high": 71.0, "low": 29.0},
{"numberOfEntrants": 24, "average": 59.4, "high": 81.0, "low": 36.0},
{"numberOfEntrants": 25, "average": 57.8, "high": 81.0, "low": 30.0},
{"numberOfEntrants": 25, "average": 49.0, "high": 77.0, "low": 20.0},
{"numberOfEntrants": 32, "average": 59.3, "high": 93.0, "low": 30.0},
{"numberOfEntrants": 22, "average": 67.6, "high": 90.0, "low": 50.0},
];
