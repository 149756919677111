import { ShootStat } from "../types/model";;

export const headers: (string | undefined)[] = ["NAME", , , , , "5th Jan", "18th Jan", , , , , , , , , , , , , , , , , , , , , , , , ];

export const results: (string | number | undefined)[][] = [
["ALLEN JOHN", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["AZZOPARDI MATT", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["BARROW DAVID", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["BEALING DAVID", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["BLUNDELL MARK", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["BROWN DAVID", "AA", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["CAMERON DEAN", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["CARR-GOMM MAH", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["CHECHOTKIN VLADYSLAV ", "AAA", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["COLE BERNARD", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["CRAWFORD MARTIN", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["CULLINGHAM SAM", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["CULLINGHAM TATE", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["DAWSON PETER", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["DEACON ROBERT", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["DE SILVA RICKY", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["DHIR VARINDER", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["DUFFELL ERIC", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["DUKE NEIL", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["DYER PAUL", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["ESCOTT PAUL", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["ESCOTT RICHARD", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["ESCOTT TIM", "AA", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND A", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["FARAZAMAND J", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["HEALEY LES", "AAA", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["HIBBERT PAUL", "AAA", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["HOLLOWAY NICK", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["HOOLEY PETER", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["HOWORTH CHRIS", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["JACKSON COLIN", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["JAY CHRIS", "AA", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["MICHAEL JOHNSON", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["KING JONATHAN", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["MANUEL RAYNOR", "AA", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["MARCHETTI ALAN", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["MORGAN-RUSSELL TIM", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["O'GORMAN ROY", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["PAYNE BARRY", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["PRENTICE ROBERT", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["SEWELL WAYNE", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["SHARP RUSSELL", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["SMALLRIDGE ROSS", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["SMALLDRIDGE STEVE", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["SOUTHALL IAN", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["STANLEY WARWICK ", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["SULLIVAN MARTIN", "AA", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["THOMAS DUNCAN", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["TRUSTHAM MATT", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["TURNER MICHAEL", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["VAUGHAN PAYNE R", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["VOWLER ALAN", "C", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["WESTON DAVE", "A", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["WESTON JADEN", "AA", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,["WHITE ROGER", "B", 0.0, 0, 0, , , , , , , , , , , , , , , , , , , , , , , , , , ]
,];

export const shootStats: ShootStat[] = [
];
